<template>
  <section>
    <loading v-bind:dialog="isLoading"></loading>

    <v-layout>
      <v-flex xs12>
        <alert
          v-bind:tipo="notificacion.tipo"
          v-bind:mensaje="notificacion.mensaje"
          class="multi-line"
        ></alert>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs12>
        <v-card max-width="344" color="warning" dark>
          <v-card-title>
            <h3>Su saldo actual es de:</h3>
          </v-card-title>
          <v-card-text>
            <v-text-field
              readonly
              v-model="getSaltoTotal"
              append-icon="monetization_on"
              class="monto-saldo-total"
            ></v-text-field>
          </v-card-text>

          <!-- <v-card-actions>
            <v-btn outlined rounded text> Button </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-flex>
    </v-layout>
    <v-form ref="form" v-on:submit.prevent="Buscar">
      <v-layout row wrap>
        <v-flex xs12 sm6 lg3>
          <v-text-field
            label="Fecha desde"
            append-icon="event"
            type="date"
            v-model="fecini"
            autofocus
            :rules="feciniRules"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 lg3>
          <v-text-field
            label="Fecha hasta"
            append-icon="event"
            type="date"
            v-model="fecfin"
            :rules="fecfinRules"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm8 lg4>
          <v-switch
            label="Sólo comprobantes con saldo"
            v-model="compSaldo"
          ></v-switch>
        </v-flex>
        <v-flex xs12 sm4 lg2>
          <v-btn
            type="submit"
            color="black"
            outline
            block
            :disabled="isLoading"
          >
            <v-icon>search</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    <v-layout row wrap>
      <v-flex lg12>
        <v-spacer></v-spacer>
        <v-card v-show="!isLoading">
          <v-toolbar card color="white">
            <v-text-field
              flat
              solo
              disabled
              label="Listado de facturas/recibos"
              single-line
              hide-details
            ></v-text-field>
            <v-menu offset-y :nudge-left="170" :close-on-content-click="false">
              <v-btn icon slot="activator">
                <v-icon>more_vert</v-icon>
              </v-btn>
              <v-list>
                <v-list-tile
                  v-for="item in headers"
                  :key="item.value"
                  @click="changeSort(item.value)"
                >
                  <v-list-tile-title>
                    {{ item.text }}
                    <v-icon v-if="pagination.sortBy === item.value">{{
                      pagination.descending ? "arrow_downward" : "arrow_upward"
                    }}</v-icon>
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout v-resize="onResize" column>
              <v-data-table
                :headers="headers"
                :items="comprobantes"
                :pagination.sync="pagination"
                :hide-headers="isMobile"
                :class="{ mobile: isMobile }"
                :rows-per-page-items="rowsperpageitems"
              >
                <template slot="items" slot-scope="props">
                  <tr v-if="!isMobile">
                    <td>{{ props.item.fv0_fecemi | fecha }}</td>
                    <td>{{ props.item.emitidaHace }} días</td>
                    <td>{{ props.item.fv0_tipcmp }}</td>
                    <td>{{ props.item.fv0_tipfor }}</td>
                    <td>{{ props.item.fv0_sucurs }}</td>
                    <td>{{ props.item.fv0_numero }}</td>
                    <td>{{ props.item.fv0_imptot | moneda }}</td>
                    <td>{{ props.item.saldo | moneda }}</td>
                    <td>
                      <v-btn
                        color="primary"
                        fab
                        small
                        dark
                        title="Imprimir comprobante"
                        @click="facturaElectronicaPDF(props.item.fv0_codigo)"
                        v-if="props.item.nus_tipimp == 'FE'"
                      >
                        <v-icon>local_printshop</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-else>
                    <td>
                      <ul class="flex-content">
                        <li class="flex-item" data-label="Fecha">
                          {{ props.item.fv0_fecemi | fecha }}
                        </li>
                        <li class="flex-item" data-label="Emitida hace">
                          {{ props.item.emitidaHace }} días
                        </li>
                        <li class="flex-item" data-label="Comprobante">
                          {{ props.item.fv0_tipcmp }}
                        </li>
                        <li class="flex-item" data-label="Tipo">
                          {{ props.item.fv0_tipfor }}
                        </li>
                        <li class="flex-item" data-label="Sucursal">
                          {{ props.item.fv0_sucurs }}
                        </li>
                        <li class="flex-item" data-label="Nro">
                          {{ props.item.fv0_numero }}
                        </li>
                        <li class="flex-item" data-label="Obervaciones">
                          {{ props.item.fv0_observ }}
                        </li>
                        <li class="flex-item" data-label="Importe ($)">
                          {{ props.item.fv0_imptot | moneda }}
                        </li>
                        <li class="flex-item" data-label="Saldo del Comp.">
                          {{ props.item.saldo | moneda }}
                        </li>
                        <li class="flex-item" data-label="Acciones">
                          <v-btn
                            color="primary"
                            fab
                            small
                            dark
                            title="Imprimir comprobante"
                            @click="
                              facturaElectronicaPDF(props.item.fv0_codigo)
                            "
                            v-if="props.item.nus_tipimp == 'FE'"
                          >
                            <v-icon>local_printshop</v-icon>
                          </v-btn>
                        </li>
                        <li class="enter"></li>
                      </ul>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<style scoped>
.monto-saldo-total {
  font-weight: 700;
  font-size: xx-large;
}
</style>

<script>
import DataTableMixin from "@/mixins/DataTableMixin";
import utils from "@/plugins/Utils.js";
import alert from "@/components/alert/alert.vue";
import loading from "@/components/share/Loading.vue";
import ctacteservice from "@/services/CtaCteServices";
import FacturaVentaService from "@/services/FacturaVentaServices";
import FacturaVentaItemService from "@/services/FacturaVentaItemServices";
import EmpresaService from "@/services/EmpresaServices";
import ClienteService from "@/services/ClienteServices";
import userService from "@/services/UserServices";
import reporteFactura from "@/reports/factura/reporteFactura";

export default {
  name: "ConsultarCtaCte",
  mixins: [DataTableMixin, utils.mixins],
  components: { alert, loading },
  props: {
    // codcli: {
    //   type: Number,
    //   required: true
    // }
  },
  computed: {
    getSaltoTotal() {
      return this.Total.toLocaleString("ARS", {
        style: "currency",
        currency: "ARS",
      });
    },
  },
  data() {
    return {
      Total: 0,
      compSaldo: true,
      fecini: utils.fechaRestar(3, "month"),
      fecfin: utils.fechaActual,
      comprobantes: [],
      isLoading: false,
      notificacion: { mensaje: "", tipo: "error" },
      pagination: { sortBy: "fv0_fecemi" },
      isMobile: false,
      headers: [
        { text: "Fecha", align: "left", value: "fv0_fecemi" },
        { text: "Emitida hace ", value: "emitidaHace" },
        { text: "Comprobante", value: "fv0_tipcmp" },
        { text: "Tipo", value: "fv0_tipfor" },
        { text: "Sucursal", value: "fv0_sucurs" },
        { text: "Nro", value: "fv0_numero" },
        { text: "Importe ($)", value: "fv0_imptot" },
        { text: "Saldo del Comp. ($)", value: "saldo" },
        { text: "Acciones", value: "-" },
      ],
      feciniRules: [
        (v) => !!v || "Seleccione una fecha de inicio.",
        (v) =>
          v <= this.fecfin ||
          "La fecha de inicio deber ser menor o igual a la de fin.",
      ],
      fecfinRules: [
        (v) => !!v || "Seleccione una fecha de fin.",
        (v) =>
          v >= this.fecini ||
          "La fecha de fin deber ser mayor o igual a la de inicio.",
      ],
    };
  },
  created() {
    this.Cargar();
    
    if (userService.IsAdmin()) {
      this.compSaldo = true;
    }else{
      this.compSaldo = false;
    }
  },
  methods: {
    Cargar() {
      //Verificamos que el codigo de cliente se mayor a 0
      let curcliente = ClienteService.current();
      if (curcliente.cli_codigo < 1) {
        this.notificacion.mensaje = "Seleccione un cliente para continuar";
        return false;
      }
      this.isLoading = true;
      ctacteservice
        .consultar(curcliente.cli_codigo)
        .then((res) => {
          this.notificacion.mensaje = "";
          //Limpiamos el array comprobantes
          this.comprobantes = [];
          this.Total = 0;
          //Cargamos solo los elementos que cumplan
          //con las condiciones de filtrado
          this.comprobantes = res.filter((Element) => {
            this.Total += Number.parseFloat(Element.fv0_imptot)
              ? Number.parseFloat(Element.fv0_imptot)
              : 0;

            if (
              this.IsSaldo(Element.saldo) &&
              this.filtarPorFecha(this.fecini, this.fecfin, Element.fv0_fecemi)
            ) {
              return Element;
            }
          });
        })
        .catch((err) => {
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar los registros.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    Buscar() {
      //Verificamos que se cumplan las validaciones
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.Cargar();
    },
    IsSaldo(saldo) {
      let mostrar = true;
      if (this.compSaldo && saldo == 0) {
        mostrar = false;
      }
      return mostrar;
    },
    async facturaElectronicaPDF(codigo) {
      try {
        let data = {
          fv0: null,
          QR: null,
          fv1: null,
          emp: null,
          cli: null,
          totales: null,
          tributos: null,
          carcab: null
        };
        // console.log("data:");
        // console.log(data);
        await Promise.all([
          FacturaVentaService.consultarElectronica(codigo), 
          FacturaVentaService.consultarQr(codigo),
          FacturaVentaItemService.consultar(codigo),
          EmpresaService.consultar(),
          FacturaVentaService.consultarCuadroTotales(codigo),
          FacturaVentaService.consultarOtrosTributos(codigo),
          FacturaVentaService.consultarCaractCabecera(codigo)
        ]).then((values) => {
          data.fv0 = values[0];
          data.QR  = values[1];
          data.fv1 = values[2];
          data.emp = values[3];
          data.totales = values[4];
          data.tributos = values[5];
          data.carcab = values[6];
        });

        data.cli = await ClienteService.getbyID(data.fv0.fv0_codcli);

        reporteFactura.generarPDF(data);
      } catch (err) {
        console.log(err);
        this.notificacion.mensaje =
          "Ocurrió un error al intentar recuperar los registros.";
        if (err.response && err.response.data.hasOwnProperty("errorMessage")) {
          this.notificacion.mensaje += "\n" + err.response.data.errorMessage;
        }
      }
    },
  },
};
</script>